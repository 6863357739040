import moment from 'moment';

import { Stack, Typography } from '@mui/material';

import CountDown from './count-down';

type AirDropTime = {
  startTs: number;
  endTs: number;
};

const DuringAirDrop = (isSolCatToken: boolean, endTs: number) => {
  return (
    <>
      {!isSolCatToken ? (
        <Typography className="text-center text-teal-300 text-2xl font-normal font-cc uppercase">
          claim airdrop
        </Typography>
      ) : (
        <Stack className="pt-8" gap={1}>
          <Typography className="text-center text-teal-300 text-2xl font-normal font-cc uppercase">
            claim your
          </Typography>
          <Typography className="text-center text-teal-300 text-2xl font-normal font-cc uppercase">
            solcat airdrop
          </Typography>
        </Stack>
      )}
      <Stack direction="row" className="pt-3 pb-5">
        <Typography
          className={`text-white text-sm ${
            isSolCatToken ? 'font-dm_mono' : 'font-dm_mono_regular'
          }`}
        >
          Remaining time:
        </Typography>
        <CountDown date={new Date(endTs * 1000)} />
      </Stack>
    </>
  );
};

const BeforeAirDrop = (isSolCatToken: boolean, startDate: Date) => {
  return (
    <>
      {!isSolCatToken ? (
        <Typography className="text-center text-teal-300 text-2xl font-normal font-cc uppercase">
          claim airdrop
        </Typography>
      ) : (
        <Stack className="pt-8" gap={1}>
          <Typography className="text-center text-teal-300 text-2xl font-normal font-cc uppercase">
            check your
          </Typography>
          <Typography className="text-center text-teal-300 text-2xl font-normal font-cc uppercase">
            airdrop Amount
          </Typography>
        </Stack>
      )}
      <Stack direction="column" className="pt-3 pb-5">
        <Stack direction="row" className="justify-center">
          <Typography className="text-white text-sm font-dm_mono uppercase">
            {`Airdrop start on ${moment(startDate).format('LL')}`}
          </Typography>
        </Stack>
        <Stack direction="row" className="justify-center" gap={1}>
          <Typography className={'text-white text-sm font-dm_mono uppercase'}>
            Remaining time:
          </Typography>
          <CountDown date={new Date(startDate.getTime())} />
        </Stack>
      </Stack>
    </>
  );
};

const AfterAirdrop = (isSolCatToken: boolean) => {
  return (
    <>
      {!isSolCatToken ? (
        <Typography className="text-center text-teal-300 text-2xl font-normal font-cc uppercase">
          claim airdrop
        </Typography>
      ) : (
        <Stack className="pt-8" gap={1}>
          <Typography className="text-center text-teal-300 text-2xl font-normal font-cc uppercase">
            claim your
          </Typography>
          <Typography className="text-center text-teal-300 text-2xl font-normal font-cc uppercase">
            solcat airdrop
          </Typography>
        </Stack>
      )}
      <Stack direction="row" className="pt-3 pb-5" gap={2}>
        <Typography className="text-white text-md font-dm_mono uppercase">
          Airdrop ended, thank you!
        </Typography>
      </Stack>
    </>
  );
};

const mobileView = (isSolCatToken: boolean, airdropTime: AirDropTime) => {
  const { startTs, endTs } = airdropTime;
  const currentTime = new Date();

  if (currentTime.getTime() < startTs * 1000) {
    return BeforeAirDrop(isSolCatToken, new Date(startTs * 1000));
  }
  if (currentTime.getTime() < endTs * 1000) {
    return DuringAirDrop(isSolCatToken, endTs);
  }
  return AfterAirdrop(isSolCatToken);
};

export default mobileView;
