import Description from '@/components/description';
import CatThankyou from '@/components/description/thank-you';
import useMobileDetection from '@/hooks/use-mobile-detection';
import { Box, Grid } from '@mui/material';
import Image from 'next/image';

import { useDistributorData } from '../../hooks/use-distributor-data';
import { useSolana } from '../../providers/solana';
import Claim from '../claim';
import ConnectWallet from '../connect-wallet';
import CountDownContent from '../count-down-content';
import ProjectDetails from '../project-details';
import { useEffect, useState } from 'react';

const Home = () => {
  const isMobile = useMobileDetection();
  const { connected } = useSolana();
  const { data: distributorData } = useDistributorData();
  const isAirdropEnded = (distributorData?.endTs ?? 0) < Date.now() / 1000;
  const showCat = !isMobile && isAirdropEnded;
  const [windowWidth, setWindowWidth] = useState(0);
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL;
  const isSolCatToken = tokenSymbol == 'SOLCAT' || !tokenSymbol;
  let classStyle = isMobile
    ? isSolCatToken
      ? 'h-[calc(100vh_-100px)] rounded-[20px] px-6 '
      : 'rounded-[20px] px-6 pt-8 pb-14 '
    : isSolCatToken
    ? 'h-[calc(100vh_-135px)] rounded-[40px] px-10 pt-6 pb-6 '
    : 'rounded-[40px] px-16 pt-12 pb-28 ';

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  classStyle += isSolCatToken ? 'bg-gradient-page' : 'bg-gradient-page-2';

  return (
    <>
      {!isSolCatToken && (
        <Image
          src={`/images/banners/${tokenSymbol}.png`}
          width={790}
          alt={tokenSymbol}
          sizes="100vw"
          height={263.33}
          className={`${isMobile ? 'rounded-[20px]' : 'rounded-[32px]'}`}
          layout="responsive"
        />
      )}

      <Box
        className={`${
          isMobile ? 'mt-2 rounded-[20px]' : 'mt-1 rounded-[40px]'
        } relative ${
          windowWidth <= 1200 ? '' : 'border-2 border-teal-300 border-solid '
        } `}
      >
        <Grid container className={`${classStyle}`}>
          {!isSolCatToken && (
            <Grid item xs={12} className="w-full">
              <ProjectDetails />
            </Grid>
          )}
          <Grid item xs={12} className="w-full">
            <CountDownContent />
          </Grid>
          <Grid
            item
            xs={12}
            className={`w-full px-6 ${isSolCatToken ? '' : 'pb-3 md:pb-6'} `}
          >
            {!isAirdropEnded && !connected && <ConnectWallet />}
            {!isAirdropEnded && connected && <Claim />}
          </Grid>
          {isSolCatToken && (
            <Grid item xs={12} className="mt-8">
              <Description />
            </Grid>
          )}
        </Grid>
        {isSolCatToken && showCat && <CatThankyou />}
      </Box>
    </>
  );
};

export default Home;
