import { useQuery } from 'react-query';

import { PublicKey } from '@solana/web3.js';

import { fetchMerkleProof } from '../utils/apis/merkle-tree';

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const useProof = (publicKey: PublicKey) => {
  const LOCAL_STORAGE_KEY = `merkleProof_${publicKey.toString()}`;

  const fetchProof = async () => {
    const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedData) {
      const { proof, timestamp } = JSON.parse(storedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return proof;
      }
    }
    const proof = await fetchMerkleProof(publicKey);
    const dataToStore = { proof, timestamp: Date.now() };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dataToStore));
    return proof;
  };

  const {
    data: proofData,
    isLoading: isProofLoading,
    refetch: refetchProof,
  } = useQuery(['proof', publicKey], fetchProof, {
    enabled: !!publicKey,
    staleTime: CACHE_DURATION,
  });

  const { proof, index, amount } = proofData ?? {
    proof: [],
    index: 0,
    amount: 0,
  };

  return {
    data: { proof, index, amount },
    isLoading: isProofLoading,
    refetch: refetchProof,
  };
};
