import React from 'react';

import moment from 'moment';

import useMobileDetection from '@/root/src/hooks/use-mobile-detection';
import { Stack, Typography } from '@mui/material';

import { useDistributorData } from '../../hooks/use-distributor-data';
import CountDown from './count-down';
import mobileView from './mobile';

type AirDropTime = {
  startTs: number;
  endTs: number;
};

const DuringAirDrop = (isSolCatToken: boolean, endTs: number) => {
  return (
    <>
      {!isSolCatToken ? (
        <Typography className="text-center text-teal-300 text-[32px] leading-10 font-normal font-cc uppercase">
          claim airdrop
        </Typography>
      ) : (
        <Stack className="pt-8" gap={1}>
          <Typography className="text-center text-teal-300 text-5xl font-normal font-cc uppercase">
            claim your
          </Typography>
          <Typography className="text-center text-teal-300 text-5xl font-normal font-cc uppercase">
            solcat airdrop
          </Typography>
        </Stack>
      )}
      <Stack direction="row" className="pt-6 pb-12" gap={2}>
        <Typography
          className={`text-white text-[32px] leading-10 ${
            isSolCatToken ? 'font-dm_mono' : 'font-dm_mono_regular'
          }`}
        >
          Remaining time:
        </Typography>
        <CountDown date={new Date(endTs * 1000)} />
      </Stack>
    </>
  );
};

const BeforeAirDrop = (isSolCatToken: boolean, startDate: Date) => {
  return (
    <>
      {!isSolCatToken ? (
        <Typography className="text-center text-teal-300 text-[32px] leading-10 font-normal font-cc uppercase">
          claim airdrop
        </Typography>
      ) : (
        <Stack className="pt-8" gap={1}>
          <Typography className="text-center text-teal-300 text-5xl font-normal font-cc uppercase">
            check your
          </Typography>
          <Typography className="text-center text-teal-300 text-5xl font-normal font-cc uppercase">
            airdrop Amount
          </Typography>
        </Stack>
      )}
      <Stack direction="column" className="pt-6 pb-12" gap={2}>
        <Stack direction="row" className="w-full justify-center">
          <Typography className="text-white text-[32px] font-dm_mono uppercase">
            {`Airdrop start on ${moment(startDate).format('LL')}`}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} className="w-full justify-center">
          <Typography
            className={
              'text-white text-[32px] leading-10 font-dm_mono uppercase'
            }
          >
            Remaining time:
          </Typography>
          <CountDown date={new Date(startDate.getTime())} />
        </Stack>
      </Stack>
    </>
  );
};

const AfterAirdrop = (isSolCatToken: boolean) => {
  return (
    <>
      {!isSolCatToken ? (
        <Typography className="text-center text-teal-300 text-5xl font-normal font-cc uppercase">
          claim airdrop
        </Typography>
      ) : (
        <Stack className="pt-8" gap={1}>
          <Typography className="text-center text-teal-300 text-5xl font-normal font-cc uppercase">
            claim your
          </Typography>
          <Typography className="text-center text-teal-300 text-5xl font-normal font-cc uppercase">
            solcat airdrop
          </Typography>
        </Stack>
      )}
      <Stack direction="row" className="pt-6 pb-12" gap={2}>
        <Typography className="text-white text-3xl font-dm_mono uppercase">
          Airdrop ended, thank you!
        </Typography>
      </Stack>
    </>
  );
};

const Content = (isSolCatToken: boolean, airdropTime: AirDropTime) => {
  const { startTs, endTs } = airdropTime;
  const currentTime = new Date();

  if (currentTime.getTime() < startTs * 1000) {
    return BeforeAirDrop(isSolCatToken, new Date(startTs * 1000));
  }
  if (currentTime.getTime() > endTs * 1000) {
    return AfterAirdrop(isSolCatToken);
  }

  return DuringAirDrop(isSolCatToken, endTs);
};

const CountDownContent = () => {
  const isMobile = useMobileDetection();
  const { data: distributorData } = useDistributorData();
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL;
  const isSolCatToken = tokenSymbol == 'SOLCAT' || !tokenSymbol;

  if (!distributorData) {
    return null;
  }

  return (
    <Stack
      alignItems="center"
      data-testid="count-down"
      className={`${isSolCatToken ? '' : isMobile ? 'pt-3' : 'pt-6'}`}
    >
      {isMobile
        ? mobileView(isSolCatToken, distributorData)
        : Content(isSolCatToken, distributorData)}
    </Stack>
  );
};

export default CountDownContent;
