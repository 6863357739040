import axios from 'axios';

import { PublicKey } from '@solana/web3.js';

export async function updateClaimStatusApi(publicKey: PublicKey): Promise<{
  walletAddress: string;
  amount: number;
  isClaimed: boolean;
}> {
  const response = await axios.patch(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/users/claim`,
    {},
    {
      params: {
        wallet_address: publicKey.toBase58(),
        token_symbol: process.env.NEXT_PUBLIC_TOKEN_SYMBOL,
      },
    },
  );
  return {
    walletAddress: response.data.wallet_address,
    amount: response.data.amount,
    isClaimed: response.data.is_claimed,
  };
}
