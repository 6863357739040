'use client';
import Countdown, { zeroPad } from 'react-countdown';

import useMobileDetection from '@/root/src/hooks/use-mobile-detection';
import { Stack, Typography } from '@mui/material';

const Item = ({ time, label }: { time: number; label: string }) => {
  const isMobile = useMobileDetection();
  const textSize = isMobile ? 'text-sm' : 'text-[32px] leading-10';
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL;
  const isSolCatToken = tokenSymbol == 'SOLCAT' || !tokenSymbol;

  return (
    <Stack alignItems="center">
      <Typography
        className={`text-white ${textSize} ${
          isSolCatToken ? 'font-dm_mono' : 'font-dm_mono_regular'
        } `}
      >
        {`${zeroPad(time)}${label}`}
      </Typography>
    </Stack>
  );
};

interface CountDownProps {
  date: string | Date;
}
function CountDown(props: CountDownProps) {
  const { date } = props;
  const isMobile = useMobileDetection();

  return (
    <Countdown
      date={date}
      renderer={({ days, hours, minutes, seconds }) => (
        <Stack direction="row" gap={isMobile ? 1 : 2}>
          <Item time={days} label="d" />
          <Item time={hours} label="h" />
          <Item time={minutes} label="m" />
          <Item time={seconds} label="s" />
        </Stack>
      )}
    />
  );
}

export default CountDown;
