import Image from 'next/image';

import CatImage from '@/assets/cat.png';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';

const CatThankyou = () => {
    return (
      <Stack className='absolute -bottom-1.5 left-0'>
        <Box className='relative'>
          <Typography className='absolute -top-16 -right-20 w-11/12 text-center text-white text-2xl font-dm_mono'>Thanks for your contribution, folks!</Typography>
          <Image src={CatImage} alt="cat" width={340} height={340} />
        </Box>
      </Stack>
    );
}

export default CatThankyou;
