import Seo from '@/components/base/seo';
import Home from '@/components/home';
import useTrans from '@/hooks/use-trans';
import { MainLayout } from '@/layouts/main-layout';

const Index = () => {
  const trans = useTrans();

  return (
    <>
      {process.env.NEXT_PUBLIC_ENABLE_SEO === 'true' && (
        <Seo
          data={{
            title: trans.meta.title,
            description: trans.meta.description,
            thumbnailUrl: trans.meta.thumbnail_url,
            url: `${process.env.NEXT_PUBLIC_SITE_URL}`,
          }}
        />
      )}
      <MainLayout>
        <Home />
      </MainLayout>
    </>
  );
};

export default Index;
