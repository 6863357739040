import Image from 'next/image';

import useMobileDetection from '@/root/src/hooks/use-mobile-detection';
import {
  IconName,
  staticImportIcon,
} from '@/root/src/utils/static-import-icon';
import { Divider, Fade, Link, Stack, Tooltip, Typography } from '@mui/material';

const SocialButton = () => {
  return (
    <Stack direction="row" gap={3} className="mt-4">
      <Stack className="social-button-stack">
        <Tooltip title="Telegram" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-telegram"
            href="https://t.me/catsolhat1"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={staticImportIcon(IconName.TELEGRAM)}
              alt={IconName.TELEGRAM}
              width={25}
            />
          </Link>
        </Tooltip>
      </Stack>
      <Stack className="social-button-stack">
        <Tooltip title="X" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-x"
            href="https://x.com/catsolhat"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={staticImportIcon(IconName.X)}
              alt={IconName.X}
              width={25}
            />
          </Link>
        </Tooltip>
      </Stack>
      <Stack className="social-button-stack">
        <Tooltip title="Youtube" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-youtube"
            href="https://www.youtube.com/@catsolhat"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={staticImportIcon(IconName.YOUTUBE)}
              alt={IconName.YOUTUBE}
              width={25}
            />
          </Link>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

const MobileView = () => {
  return (
    <Stack alignItems="center">
      <Typography className="text-white text-opacity-50 font-dm_mono uppercase text-sm">
        Airdrop Criteria
      </Typography>
      <Divider
        data-testid="description-divider"
        className="w-9/12 mt-2"
        style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.16)' }}
      />
      <Typography className="w-9/12 text-center text-white mt-2 text-opacity-50 font-dm_mono uppercase text-sm">
        Early Contributors, Active Wallets on Solana DEXs, Saga Phone Buyers,
        Top NFT Collections Holders,...
      </Typography>
      <Link
        data-testid="full-details-link"
        href="https://catsolhat.medium.com/catsolhat-super-airdrop-8b7f8723617f"
        target="_blank"
        rel="noopener"
      >
        <Typography className="text-white mt-2 font-medium font-dm_mono underline uppercase text-sm">
          Check out the full details here
        </Typography>
      </Link>
      <SocialButton />
    </Stack>
  );
};

const DesktopView = () => {
  return (
    <Stack alignItems="center">
      <Typography className="text-white text-opacity-50 font-dm_mono uppercase">
        Airdrop Criteria
      </Typography>
      <Divider
        data-testid="description-divider"
        className="w-9/12 mt-4"
        style={{ height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.16)' }}
      />
      <Typography className="w-9/12 text-center text-white mt-4 text-opacity-50 font-dm_mono uppercase">
        Early Contributors, Active Wallets on Solana DEXs, Saga Phone Buyers,
        Top NFT Collections Holders,...
      </Typography>
      <Link
        data-testid="full-details-link"
        href="https://catsolhat.medium.com/catsolhat-super-airdrop-8b7f8723617f"
        target="_blank"
        rel="noopener"
      >
        <Typography className="text-white mt-4 font-medium font-dm_mono underline uppercase">
          Check out the full details here
        </Typography>
      </Link>
      <SocialButton />
    </Stack>
  );
};

const Description = () => {
  const isMobile = useMobileDetection();
  if (isMobile) {
    return <MobileView />;
  }
  return <DesktopView />;
};

export default Description;
