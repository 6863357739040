import React from 'react';

import moment from 'moment';
import { useMutation, useQuery } from 'react-query';

import { BN } from '@coral-xyz/anchor';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';

import { MICRO_TOKEN } from '../../constants';
import { useDistributorData } from '../../hooks/use-distributor-data';
import { useProof } from '../../hooks/use-merkle-tree';
import useMobileDetection from '../../hooks/use-mobile-detection';
import { useSolana } from '../../providers/solana';
import { updateClaimStatusApi } from '../../utils/apis/claim';
import { isSolcatToken } from '../../utils/helpers';

const Claim = () => {
  const { data: distributorData, error, isLoading } = useDistributorData();
  const startDateTimestamp = Number(distributorData?.startTs ?? 0);
  const isBeforeAirdropPeriod = startDateTimestamp > Date.now() / 1000;
  const startDate = moment(startDateTimestamp * 1000).format('DD-MM-YYYY');
  const isMobile = useMobileDetection();
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL;
  const isSolcat = tokenSymbol == 'SOLCAT' || !tokenSymbol;

  const {
    connection,
    provider,
    program,
    ctx,
    distributorClient,
    mintPublicKey,
    merkleTreeVersion,
    distributor,
    publicKey,
    connected,
  } = useSolana();

  if (
    !connection ||
    !provider ||
    !program ||
    !ctx ||
    !distributorClient ||
    !mintPublicKey ||
    !merkleTreeVersion ||
    !distributor ||
    !publicKey
  ) {
    return <div>Loading...</div>;
  }

  const {
    data: { proof, index, amount },
    isLoading: isProofLoading,
    refetch: refetchProof,
  } = useProof(publicKey);

  const { mutate: claim, isLoading: isClaiming } = useMutation(
    async () => {
      const claimAmount = new BN(amount);
      const proofBufferArray = proof.map((p: string) => Buffer.from(p, 'hex'));
      const tx = await distributorClient.newClaim(
        mintPublicKey,
        new BN(index),
        claimAmount,
        proofBufferArray,
        new BN(merkleTreeVersion),
      );
      const signature = await tx.buildAndExecute();
      const latestBlockHash = await connection.getLatestBlockhash();
      await connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: signature,
      });
      await updateClaimStatusApi(publicKey);
    },
    {
      onSuccess: () => {
        refetchClaimStatus();
      },
    },
  );

  const {
    data: isClaimed,
    isLoading: isClaimInfoLoading,
    refetch: refetchClaimStatus,
  } = useQuery(
    ['isClaimed', publicKey],
    async () => {
      if (!publicKey) {
        throw new Error('Public key is null');
      }
      const claimStatusPDA = distributorClient.pda.claim_status(
        publicKey,
        distributor.key,
      );
      const accountInfo = await connection.getAccountInfo(claimStatusPDA.key);
      if (!accountInfo) {
        return false;
      }
      const claimStatus =
        await distributorClient.ctx.program.account.claimStatus.fetch(
          claimStatusPDA.key,
        );
      return claimStatus.isClaimed;
    },
    {
      enabled: connected,
      staleTime: 1 * 60 * 1000,
    },
  );

  const MobileView = ({
    tokenSymbol,
    isSolcat,
  }: {
    tokenSymbol: string;
    isSolcat: boolean;
  }) => (
    <Stack
      className={`bg-purple-200 rounded-2xl flex-col mx-auto justify-evenly ${
        isSolcat ? 'h-[180px]' : 'p-6'
      }`}
      gap={isSolcat ? 0 : 2}
    >
      {isProofLoading ? (
        <CircularProgress className="mx-auto text-[#9945ff]" />
      ) : proof.length === 0 ? (
        <Stack
          className="flex flex-col justify-evenly px-2"
          gap={1}
          alignItems="center"
        >
          <Typography className="uppercase text-[#0F0F0F] text-sm font-dm_mono">
            ahhh dang it
          </Typography>
          <Typography className="uppercase text-[#0F0F0F] text-xl font-cc text-center">
            you are not eligible
          </Typography>
        </Stack>
      ) : (
        <>
          <Stack className="flex flex-col justify-between items-center" gap={1}>
            <Typography className="uppercase text-[#0F0F0F] text-sm font-dm_mono text-center">
              Congrats, You are Eligible!{' '}
              <span className="block">Your allocation:</span>
            </Typography>
            <Typography className="uppercase text-[#0F0F0F] text-[20px] text-center font-cc">
              <span className="font-rammetto">{`${amount / MICRO_TOKEN}`}</span>
              <span className="font-cc ml-1">{tokenSymbol}</span>
            </Typography>
          </Stack>
          {distributorData && isBeforeAirdropPeriod ? (
            <Typography className="text-[#0F0F0F] text-sm font-dm_mono text-center uppercase bg-[#DCC1FF] px-4 py-2 rounded-full mx-auto">
              Comeback on {startDate} to claim
            </Typography>
          ) : isClaimed ? (
            <Button
              className="px-6 py-3 bg-black/5 rounded-[100px] justify-center items-center gap-2.5 inline-flex text-xl font-normal uppercase font-cc mx-auto "
              disabled={true}
            >
              Claimed
            </Button>
          ) : (
            <Button
              className="px-6 py-3 text-white bg-[#9945ff] rounded-[100px] justify-center items-center gap-2.5 inline-flex text-xl font-normal hover:bg-opacity-80 uppercase font-cc mx-auto "
              onClick={() => claim()}
              disabled={isClaiming || isClaimInfoLoading}
            >
              {isClaiming || isClaimInfoLoading ? (
                <CircularProgress size={24} className="text-white" />
              ) : (
                'Claim Now'
              )}
            </Button>
          )}
        </>
      )}
    </Stack>
  );

  const DesktopView = ({
    tokenSymbol,
    isSolcat,
  }: {
    tokenSymbol: string;
    isSolcat: boolean;
  }) => (
    <Stack
      className={`w-full max-w-[768px] bg-purple-200 ${
        isSolcat ? 'rounded-2xl' : 'rounded-[40px]'
      } flex-col mx-auto justify-evenly  ${isSolcat ? 'h-[180px]' : 'p-8'}`}
      gap={isSolcat ? 0 : 4}
    >
      {isProofLoading ? (
        <CircularProgress className="mx-auto text-[#9945ff]" />
      ) : proof.length === 0 ? (
        <Stack className="flex flex-col justify-evenly">
          <Typography className="uppercase text-[#0F0F0F] text-[20px] font-dm_mono text-center">
            ahhh dang it
          </Typography>
          <Typography className="uppercase text-[#0F0F0F] text-[32px] font-cc text-center">
            you are not eligible
          </Typography>
        </Stack>
      ) : (
        <>
          <Stack className="flex flex-col justify-between items-center">
            <Typography
              className={`text-[#0F0F0F] text-[20px] ${
                isSolcat ? 'font-dm_mono' : 'font-dm_mono_regular'
              } text-center`}
            >
              Congrats, You are Eligible! <span>Your allocation:</span>
            </Typography>
            <Typography className="uppercase text-[#0F0F0F] text-[32px] text-center font-cc">
              <span className="font-rammetto">{`${amount / MICRO_TOKEN}`}</span>
              <span className="font-cc ml-1">{tokenSymbol}</span>
            </Typography>
          </Stack>
          {distributorData && isBeforeAirdropPeriod ? (
            <Typography className="text-[#0F0F0F] text-xl font-dm_mono text-center uppercase bg-[#DCC1FF] px-4 py-2 rounded-full mx-auto">
              Comeback on {startDate} to claim
            </Typography>
          ) : isClaimed ? (
            <Button
              className="px-6 py-3 bg-black/5 rounded-[100px] justify-center items-center gap-2.5 inline-flex text-xl font-normal uppercase font-cc mx-auto "
              disabled={true}
            >
              Claimed
            </Button>
          ) : (
            <Button
              className="px-6 py-3 text-white bg-[#9945ff] rounded-[100px] justify-center items-center gap-2.5 inline-flex text-xl font-normal hover:bg-opacity-80 uppercase font-cc mx-auto "
              onClick={() => claim()}
              disabled={isClaiming || isClaimInfoLoading}
            >
              {isClaiming || isClaimInfoLoading ? (
                <CircularProgress size={24} className="text-white" />
              ) : (
                'Claim Now'
              )}
            </Button>
          )}
        </>
      )}
    </Stack>
  );

  if (isMobile) {
    return (
      <MobileView
        tokenSymbol={`${isSolcatToken(tokenSymbol) ? `solcat` : tokenSymbol}`}
        isSolcat={isSolcat}
      />
    );
  }
  return (
    <DesktopView
      tokenSymbol={`${isSolcatToken(tokenSymbol) ? `solcat` : tokenSymbol}`}
      isSolcat={isSolcat}
    />
  );
};

export default Claim;
